import React from 'react'

//Components
import { Card } from "../../anti/card/card"

const ArticleList = ({ lang, items, slug, getDate }) => {

   //States **************************************************************************** //
   const [state, setState] = React.useState([]);
   //States **************************************************************************** //

   //Functions ************************************************************************* //
   const handleGetState = () => {
      let newState = [];
      if (items) {
         items.edges.forEach(post => {
            //!IF SLUG = "NEWS_EVENTS"
            if (slug === "news-events") {
               // let { node } = post
               // let { translation } = node
               //!IF LANG ID START ------
               if (lang === "ID") {
                  const postLang = post.node.translation
                  if (postLang) {
                     newState.push({
                        key: postLang.id,
                        label: `${postLang.categories.nodes[0].name} - ${getDate(postLang.date, lang, { time: false })}`,
                        title: postLang.title,
                        text: postLang.excerpt ||
                           "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        img: postLang.featuredImage
                           ? postLang.featuredImage.sourceUrl
                           : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                        link: `/id/news-events/${postLang?.slug || post.node.slug}/`
                     })
                  } else {
                     newState.push({
                        key: post.node.id,
                        label: `${post.node.categories.nodes[0].name} - ${getDate(post.node.date, lang, { time: false })}`,
                        title: post.node.title,
                        text: post.node.excerpt ||
                           "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        img: post.node.featuredImage
                           ? post.node.featuredImage.sourceUrl
                           : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                        link: `/id/news-events/${post.node.slug}/`
                     })
                  }

                  //!IF LANG ID END ------

               } else if (lang === "EN") {

                  newState.push({
                     key: post.node.id,
                     label: `${post.node.categories.nodes[0].name} - ${getDate(post.node.date, lang, { time: false })}`,
                     title: post.node.title,
                     text: post.node.excerpt ||
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                     img: post.node.featuredImage
                        ? post.node.featuredImage.sourceUrl
                        : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                     link: `/news-events/${post.node.slug}/`
                  })

               }
            } else {
               if (lang === "ID") {
                  const postLang = post.node.translation
                  if (postLang) {
                     //!IF Translation True
                     newState.push({
                        key: postLang.id,
                        label: `${postLang.categories?.nodes[0]?.name} - ${getDate(postLang.date, lang, { time: false })}`,
                        title: postLang.title,
                        text: postLang.excerpt ||
                           "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        img: postLang.featuredImage
                           ? postLang.featuredImage?.sourceUrl
                           : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                        link: `/id/news-events/${postLang.node?.slug || postLang.slug || post.node?.slug || post.slug}/`
                     })

                  } else {
                     //!IF Translation False
                     newState.push({
                        key: post.node.id,
                        label: `${post.node.categories.nodes[0].name} - ${getDate(post.node.date, lang, { time: false })}`,
                        title: post.node.title,
                        text: post.node.excerpt ||
                           "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                        img: post.node.featuredImage
                           ? post.node.featuredImage.sourceUrl
                           : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                        link: `/id/news-events/${post.node.slug}/`
                     })
                  }

               } else if (lang === "EN") {
                  newState.push({
                     key: post.node.id,
                     label: `${post.node.categories.nodes[0].name} - ${getDate(post.node.date, lang, { time: false })}`,
                     title: post.node.title,
                     text: post.node.excerpt ||
                        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
                     img: post.node.featuredImage
                        ? post.node.featuredImage.sourceUrl
                        : "https://beryl-ks79.antikode.dev/app/uploads/2020/08/img-thumbnail-wellness-1024x570.jpg",
                     link: `/news-events/${post.node.slug}/`
                  })
               }

            }
         })
      }
      setState(newState)
   }
   //Functions ************************************************************************* //

   //React Operations ****************************************************************** //
   React.useEffect(handleGetState, [lang, slug, items])
   //React Operations ****************************************************************** //

   return (
      <>
         {state.length > 0 && (
            <>
               {state.map((item, i) => (
                  <Card
                     key={i}
                     variant="basic"
                     colLeft="col-md-5"
                     colRight="col-md-7"
                     label={item.label}
                     title={item.title}
                     text={item.text}
                     img={item.img}
                     imgHeight="h-ratio-3-2"
                     link={item.link}
                     className="card-post card-post-recommended mb-3"
                  />
               ))}
            </>
         )}
      </>
   )

}



export default ArticleList;